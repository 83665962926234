// Lib Imports
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components Imports
import Loader from 'granite-admin/core/components/Loader';
import { useToast } from 'granite-admin/core/components/Toast';

// CORE Imports
import useEmitter from 'granite-admin/utils/useEmitter';
import usePreferences from 'granite-admin/utils/usePreferences';
import { getDefaultDashboard } from 'granite-admin/aggregate/controllers/dashboard';
import * as OrganisationDucks from 'granite-admin/organisations/ducks/organisations';
import * as UserDucks from 'granite-admin/accounts/ducks/user';

// Application Imports
import { fetchEventGroups } from 'common/commonGatewaysControllers/controllers/common';
import { COMMON_EVENTS } from 'common/commonGatewaysControllers/controllers/events';
import { getFormatedDate } from 'common/CommonFunction.js';
import { fetchCurrGrp } from 'common/utils';
import { REDIRECT_ROUTES } from 'common/constants';
import { ERROR_MESSAGES } from 'pages/dashboard/strings';
import { LoaderBox } from 'common/styledComponents/LoaderBox';

const MyBoard = ({ navigate, userOrganisations, userProfile }) => {
  const { errorToast } = useToast();
  const [isGroupFetch, setIsGroupFetch] = useState(false);
  const [routeGroupValues, setRouteGroupValues] = useState({});
  const { fetchLoading, preferences: { config: { default_dashboard = {} } = {} } = {} } = usePreferences({
    route: REDIRECT_ROUTES.DASHBOARD,
    autoSavePreference: true,
  });

  const currentGroup = useMemo(() => fetchCurrGrp(userProfile) || 'AM', []);

  const date = useMemo(() => {
    const currentDate = getFormatedDate(new Date(), userProfile?.userTimezone);
    return {
      name: 'flatpicker_date',
      type: 'date',
      label: 'Date',
      customFormat: 'yyyy-MM-dd',
      value: currentDate,
    };
  }, []);

  const eventEmitter = useEmitter(event => {
    switch (event.type) {
      case COMMON_EVENTS.DEFAULT_DASHBOARD:
        navigate(
          `${REDIRECT_ROUTES.MY_BOARD}/${event.data.pk}/${event.data.name}?Select Group=${encodeURIComponent(
            JSON.stringify(routeGroupValues),
          )}&Date=${encodeURIComponent(JSON.stringify(date))}`,
          {
            replace: true,
          },
        );
        break;
      case COMMON_EVENTS.FETCH_EVENT_GROUP_SUCCESS:
        setRouteGroupValues({
          name: 'select',
          type: 'select',
          label: 'Select Group',
          options: event?.data?.map(i => i.groupName),
          value: currentGroup,
        });
        setIsGroupFetch(true);
        break;
      case COMMON_EVENTS.FETCH_EVENT_GROUP_FAILURE:
        errorToast(event?.data?.title || ERROR_MESSAGES.FAILED_TO_FETCH_EVENTGROUP);
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    fetchEventGroups(eventEmitter);
  }, [eventEmitter]);

  // check if there are any userOrganisations if there is none, redirect to get-started page
  if (!userProfile.isSystemAdmin && userOrganisations.length === 0) {
    navigate(REDIRECT_ROUTES.GET_STARTED, { replace: true });
  }

  if (isGroupFetch && !fetchLoading) {
    if (Object.keys(default_dashboard).length === 2) {
      // getDefaultDashboard function handles the loading of either the default dashboard/0th index board, if present, or it loads the "No Dashboard Found" page otherwise.
      getDefaultDashboard(eventEmitter, default_dashboard.pk);
    } else {
      getDefaultDashboard(eventEmitter);
    }
  }

  return (
    <LoaderBox>
      <Loader />
    </LoaderBox>
  );
};

MyBoard.propTypes = {
  navigate: PropTypes.any,
  userOrganisations: PropTypes.array,
  userProfile: PropTypes.object,
};
const mapStateToProps = state => ({
  userOrganisations: OrganisationDucks.organisations(state),
  userProfile: UserDucks.profile(state),
});
export default connect(mapStateToProps)(MyBoard);
