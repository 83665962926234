// Core Imports
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import { env } from 'granite-admin/env';
// Application Imports

// Application Imports
import { URLS } from 'common/commonGatewaysControllers/gateways/endpoints';
const baseUrl = env.REACT_APP_API_BASE_URL + env.REACT_APP_ROLLCALL_API;

async function userPhoneValidation(params) {
  const payload = { new_phone: params?.phone, user_id: params?.userId };
  const { data } = await GraniteAPIClient(baseUrl).post(URLS.USER_PHONE_VALIDATION, payload);
  return data;
}

/**
 *Fetch the event groups.
 *
 * @param {object} - page, page_size
 *
 * @returns {object} - with list of event groups
 */
async function getEventGroups(payload) {
  return await GraniteAPIClient(baseUrl).get(URLS.ROUTE_GROUP, payload);
}

export default { userPhoneValidation, getEventGroups };
