// Lib imports
import _ from 'lodash/fp';

// Core imports
import GraniteError from 'granite-admin/utils/granite-error';

// Application imports
import commonAPI from 'common/commonGatewaysControllers/gateways/common-api';
import { EventTypeEntity } from 'common/commonGatewaysControllers/entities/common';
import { COMMON_EVENTS } from 'common/commonGatewaysControllers/controllers/events';

async function userPhoneValidation(values) {
  if (_.isNil(values)) {
    throw new GraniteError('userData is invalid');
  }
  try {
    return await commonAPI.userPhoneValidation(values);
  } catch (error) {
    throw new GraniteError(error);
  }
}

/**
 * Dashboard myboard and OP screens- Get the event groups.
 *
 * @param {object} - eventEmitter
 *
 * @returns {eventEmitter} - In the try block, Emits a success events with event groups list
 * @returns {eventEmitter} - In the catch block, Emits a failure event if failed to fetch
 */
async function fetchEventGroups(eventEmitter) {
  const payload = { params: { ordering: 'group_name', page_size: 10000 } };
  try {
    const { data } = await commonAPI.getEventGroups(payload);
    const eventGroupList = data ? data.results?.map(item => new EventTypeEntity(item)) : [];
    eventEmitter.emit(COMMON_EVENTS.FETCH_EVENT_GROUP_SUCCESS, eventGroupList);
  } catch (error) {
    const err = new GraniteError(error);
    eventEmitter.emit(COMMON_EVENTS.FETCH_EVENT_GROUP_FAILURE, err?.errors);
  }
}

export { userPhoneValidation, fetchEventGroups };
