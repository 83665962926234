import React, { useState, useEffect, useMemo } from 'react';
import { Layer, Box, Text, Button, List } from 'grommet';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import TransportApi from 'adminTransport/gateways/transport-api';

const StyledList = styled(List)`
  & li {
    border: none;
    padding-left: 0;
    padding-right: 0;
    & span {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
    }
  }
`;

const ConfirmLayer = ({
  setConfirmLayer,
  setRouteMessage,
  currentDate,
  confirmData,
  events,
  errorToast,
  selectedEventId,
  recipientsCount,
  isSmsType,
  messageSize,
  byteSize,
  totalBtyeSize,
}) => {
  const [eventNames, setEventNames] = useState([]);

  const messageUsed = useMemo(() => {
    // If the message size is less than or equal to the byte size allowed in one payload
    if (messageSize <= byteSize) return recipientsCount || 0;

    // Calculate the remaining bytes that exceed the initial payload size
    const remainingMsgBytes = messageSize - byteSize;
    // Calculate the number of full additional payloads needed
    const quotient = Math.floor(remainingMsgBytes / totalBtyeSize);
    const payloadSize = quotient + (remainingMsgBytes % totalBtyeSize === 0 ? 1 : 2);

    return payloadSize * recipientsCount || 0;
  }, [messageSize, byteSize, totalBtyeSize, recipientsCount]);

  useEffect(() => {
    for (let i = 0; i < events?.length; i++) {
      let event = events[i];
      for (let x of selectedEventId) {
        if (event.pk === x) setEventNames(prev => [...prev, event]);
      }
    }
  }, [events]);

  const onSubmit = async confirmData => {
    try {
      TransportApi.postMessageAllRoutes({
        confirmData,
        selectedEventId,
        sent_sms: isSmsType ? true : undefined,
        final_recipients_count: isSmsType ? messageUsed : undefined,
      });
      setConfirmLayer(false);
      setRouteMessage(false);
    } catch (error) {
      errorToast(error?.errors?.title || 'Unable to send message');
    }
  };

  return (
    <Layer onClickOutside={() => setConfirmLayer()} onEsc={() => setConfirmLayer()}>
      <Box width="30rem" pad="medium" overflow={{ vertical: 'auto' }}>
        <Box direction="row" gap="small" align="center" pad="small">
          <Text size="22px" weight="bold">
            Confirm Message
          </Text>
        </Box>
        <Box pad="small" flex={{ shrink: 0 }}>
          <Box pad={{ vertical: 'small' }}>
            <Text size="large">Please check you have selected the right routes for your message.</Text>
          </Box>
          <Box pad={{ vertical: 'small' }} gap="small">
            <Text size="xlarge">Date</Text>
            <Text size="large" weight="bold">
              {currentDate}
            </Text>
          </Box>
          <Box pad={{ vertical: 'small' }}>
            <Text size="xlarge">Recipients</Text>
            <StyledList primaryKey="eventName" data={eventNames} />
          </Box>
          <Box pad={{ vertical: 'small' }} gap="small">
            <Text size="xlarge">Message</Text>
            <Text size="large" weight="bold">
              {confirmData.message}
            </Text>
          </Box>
          <Box pad={{ vertical: 'small' }} gap="small">
            <Text size="xlarge">{isSmsType ? 'Message Tokens Used' : 'Parents to be Notified via Email'}</Text>
            <Text size="large" weight="bold">
              {isSmsType ? messageUsed : recipientsCount}
            </Text>
          </Box>
        </Box>
        <Box
          direction="row"
          gap="small"
          justify="end"
          margin={{ top: 'large', bottom: 'medium', horizontal: 'small' }}
          flex={{ shrink: 0 }}
        >
          <Button primary label="Cancel" color="cancel" onClick={() => setConfirmLayer()} />
          <Button primary label="Confirm" type="submit" color="accent-1" onClick={() => onSubmit(confirmData)} />
        </Box>
      </Box>
    </Layer>
  );
};

ConfirmLayer.propTypes = {
  setConfirmLayer: PropTypes.func,
  setRouteMessage: PropTypes.func,
  currentDate: PropTypes.any,
  confirmData: PropTypes.object,
  events: PropTypes.any,
  errorToast: PropTypes.func,
  selectedEventId: PropTypes.array,
  recipientsCount: PropTypes.number,
  isSmsType: PropTypes.string,
  messageSize: PropTypes.number,
  byteSize: PropTypes.number,
  totalBtyeSize: PropTypes.number,
};

export default ConfirmLayer;
