class EventTypeEntity {
  constructor(data) {
    this.pk = data?.pk;
    this.groupName = data?.group_name;
    this.groupCode = data?.group_code;
    this.inPortal = data?.in_portal;
    this.pmDisplay = data?.pm_display;
    this.amDisplay = data?.am_display;
    this.schoolOffCheck = data?.school_off_check;
    this.schoolCheck = data?.school_check;
    this.supervisoroffchecks = data?.supervisor_off_check;
    this.supervisorchecks = data?.supervisor_check;
    this.busoffchecks = data?.bus_off_check;
    this.busCheck = data?.bus_check;
    this.campusStopCheck = data?.campus_stop_check;
    this.showAllStops = data?.show_all_stops;
    this.restrictmultiple = data?.one_student_one_route;
    this.routeCount = data?.route_count;
    this.availableRoute = data?.available_route;
    this.supervisorCheck = data?.supervisor_check;
    this.allDay = data?.all_day;
  }
}

class RouteEntity {
  constructor(data) {
    this.pk = data?.pk;
    this.name = data?.name;
    this.eventName = data?.name;
    this.routeGroupId = data?.route_group?.pk;
    this.routeGroup = data?.route_group?.group_name || data?.route_group;
    this.transportCompanyId = data?.transport_company?.pk;
    this.transportCompany = data?.transport_company?.name || data?.transport_company;
    this.routeType = data?.route_type;
    this.routeNumber = data?.route_number ?? '';
    this.route_name = data?.name;
    this.capacity = data?.capacity;
    this.activeInSmartRoute = data?.active_in_smart_route;
    this.active = data?.is_active;
    this.approveNonAllocated = data?.approve_non_allocated;
    this.eventcolor = data?.route_color;
    this.stoplist = data?.stoplist;
    this.timezone = data?.timezone;
    this.gpsscanningmode = data?.gps_interval;
    this.estimatedStopTime = data?.estimated_stop_time;
    this.groupoff = data?.allow_groupoff;
    this.iniframe = data?.show_in_iframe;
    this.specialConsideration = data?.special_consideration;
    this.todayRouteStatus = data?.today_route_status?.replace('_', ' ');
  }
}

class RouteEventsEntity {
  constructor(data) {
    this.pk = data?.pk;
    this.date = data?.route_date;
    this.route_name = data?.route_name;
    this.eventId = data?.route?.pk;
    this.eventColor = data?.route?.route_color;
    this.eventName = data?.route?.name;
    this.groupName = data?.route?.route_group?.group_name;
    this.groupId = data?.route?.route_group?.pk;
    this.routeType = data?.route?.route_type;
    this.isMultipleDriverLogin = data?.route?.is_multiple_driver_login;
    this.schoolCheck = data?.school_check;
    this.supervisorCheck = data?.supervisor_check;
    this.busCheck = data?.transport_medium_check;
    this.supervisor_check_datetime = data?.supervisor_check_datetime;
    this.supervisor_check_off_datetime = data?.supervisor_check_off_datetime;
    this.transport_medium_check_datetime = data?.transport_medium_check_datetime;
    this.school_check_datetime = data?.school_check_datetime;
    this.school_check_off_datetime = data?.school_check_off_datetime;
    this.driver_login_datetime = data?.driver_login_datetime;
    this.teacher = data?.teacher;
    this.driver = data?.driver;
    this.supervisor = data?.supervisor;
  }
}

export { EventTypeEntity, RouteEntity, RouteEventsEntity };
